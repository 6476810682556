import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <p><strong parentName="p">{`Doing business is time consuming enough at the best of times, our digital solutions are designed to steamline and make your business more productive and efficient, saving you time and money`}</strong></p>
    <p>{`We wouldn't sell it if we didn't trust it, in fact we use everything we sell first in order to vett it completely.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      