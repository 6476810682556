import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Enterprise`}</h2>
    <ProjectCard title="Web Design" link="https://www.irez.cc" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
Incredibly fast websites designed to enhance your business presence, easy to use, edit and update.
    </ProjectCard>
    <ProjectCard title="Domain Names" link="https://www.irez.net" bg="linear-gradient(to right, #662d8c 0%, #ed1e79 100%)" mdxType="ProjectCard">
Secure your very own domain name for your next web project, thousands of extensions to choose from.
    </ProjectCard>
    <ProjectCard title="Google Workspace" link="https://referworkspace.app.goo.gl/EYxM" bg="linear-gradient(to right, #009245 0%, #fcee21 100%)" mdxType="ProjectCard">
For the ultimate in email, docs, spreadsheet, video meetings and chat plus so much more.
    </ProjectCard>
    <ProjectCard title="RezPay" link="https://www.irez.cc" bg="linear-gradient(to right, #d585ff 0%, #00ffee 100%)" mdxType="ProjectCard">
Get paid, sell your goods, upsell, recurring and installments.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      