import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Get in touch`}</h2>
    <p>{`Come say hi to me over on `}<a parentName="p" {...{
        "href": "https://www.facebook.com/irez.cc"
      }}>{`Facebook`}</a>{` or `}<a parentName="p" {...{
        "href": "https://www.instagram.com/irezcc"
      }}>{`Instagram`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      